import React from 'react';

import { Row, Col, Typography, Divider, Button } from 'antd';

import { ApplicationData } from '@bwb-buildings-mono/entities';
import * as UI from '@bwb-buildings-mono/ui';

import { features } from './features';
import { amentities } from './ammenities';

const { Paragraph, Text, Title } = Typography;

export const PHONE_NUMBER = '+1(213)212-7655';
export const EMAIL = 'manager@730kingsley.com';
const ADDRESS_NAME = 'The Kingsley Collection';
const ADDRESS_1 = '730 S. Kingsley Drive';
const ADDRESS_2 = 'Los Angeles, CA 90005';

const tours = [
  {
    name: '1BR Loft, 1 BA - ~600 SF',
    url:
      'https://www.zillow.com/view-3d-home/23690e20-af30-4857-b56d-bacbee3884e9',
  },
  {
    name: '2BR Loft, 2BA - ~750 SF',
    url:
      'https://www.zillow.com/view-3d-home/fb7ce52f-9c38-47a6-bc24-795ce42af05d',
  },
];

export const AppData: ApplicationData = {
  footer: (
    <div>
      <div>
        <Row>
          <Col md={24} xs={0}>
            <div>The Kingsley Collection</div>
            <div>
              <a href={`tel:${PHONE_NUMBER}`}>+1(213)212-7655</a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={24} xs={0}>
            <div>757 S. Kingsley Drive, Los Angeles, CA 90005</div>
          </Col>
        </Row>
        <Row>
          <Col md={24} xs={0}>
            <div>730 S. Kingsley Drive, Los Angeles, CA 90005</div>
          </Col>
        </Row>
        <Row>
          <Col md={24} xs={0}>
            <div>720 S. Kingsley Drive, Los Angeles, CA 90005</div>
          </Col>
        </Row>
      </div>
      <div style={{ fontSize: '80%', marginTop: '24px' }}>
        <div>
          {ADDRESS_NAME} is located in or around Koreatown, Wilshire Center,
          Wilshire Park, Central LA, Macarthur Park, Los Angeles
        </div>
        <div>Thank You For Looking, We Look Forward to Helping You!</div>
      </div>
    </div>
  ),
  sections: [],
};
