import React from 'react';

import 'antd/dist/antd.css';
import './app.scss';

import { MainApp } from '@bwb-buildings-mono/ui';

import { AppData, PHONE_NUMBER, EMAIL } from '../data';
import { Layout, Row, Col } from 'antd';
import * as UI from '@bwb-buildings-mono/ui';
const { Footer } = Layout;

export const App = () => {
  return (
    <Layout
      className="layout"
      style={{
        maxWidth: '100%',
        margin: '0 auto',
        display: 'flex',
        backgroundColor: 'white',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          padding: '12px',
          backgroundColor: 'white',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <div
            style={{
              fontSize: 30,
              lineHeight: 1.2,
              marginLeft: 12,
              paddingBottom: 5,
            }}
          >
            The Kingsley Collection
          </div>
          <div
            style={{
              textAlign: 'center',
              fontSize: '85%',
              marginLeft: '12px',
              paddingBottom: 5,
            }}
          >
            730 S. Kingsley Dr.
            <br />
            Los Angeles, CA 90005
          </div>
        </div>
      </div>
      <div
        style={{
          textAlign: 'center',
          fontSize: 28,
          fontWeight: 'bold',
          padding: '10px 30px',
        }}
      >
        New Apartments in central Koreatown, Los Angeles
      </div>

      <div>
        <div
          style={{
            padding: 10,
            height: '100%',
            textAlign: 'center',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'rgba(0, 0, 255, 0.05)',
          }}
        >
          <h3>Schedule a tour with our on-site manager:</h3>
          <div>
            <h1 style={{ marginBottom: 0 }}>
              <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
            </h1>
            <div>&nbsp;</div>
            <h1 style={{ marginBottom: 0 }}>
              <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
            </h1>
            <div>Call or email any time!</div>
          </div>
        </div>
      </div>

      <Row style={{ padding: '20px 0px' }} gutter={10}>
        <Col xs={0} sm={0} lg={1}></Col>
        <Col
          xs={24}
          md={24}
          lg={7}
          style={{ textAlign: 'center', padding: 10 }}
        >
          <div
            style={{
              border: '1px solid #DDD',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              borderRadius: 10,
              padding: 10,
            }}
          >
            <a
              href="http://www.720kingsley.com"
              style={{ width: '100%', justifyContent: 'center' }}
            >
              <img
                style={{ width: '100%', maxWidth: 220, margin: 10 }}
                alt={'720 Kingsley'}
                src="./assets/logo.png"
              />
              <div
                style={{
                  fontSize: 40,
                  fontWeight: 'bold',
                  color: '#000',
                  padding: 10,
                }}
              >
                720 S. Kingsley Dr.
              </div>
              <div style={{ fontSize: 20, padding: 10 }}>
                http://www.720kingsley.com
              </div>
              <div style={{ color: '#000' }}>
                720 S. Kingsley Dr.
                <br />
                Los Angeles, CA 90005
              </div>
            </a>
          </div>
        </Col>
        <Col
          xs={24}
          md={24}
          lg={8}
          style={{ textAlign: 'center', padding: 10 }}
        >
          <div
            style={{
              border: '1px solid #DDD',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              borderRadius: 10,
              padding: 10,
            }}
          >
            <a
              href="http://www.730kingsley.com"
              style={{
                flex: 3,
                flexShrink: 0,
                minWidth: 400,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                style={{ width: '100%', maxWidth: 400, margin: 20 }}
                alt={'720 Kingsley'}
                src="./assets/Logo-H-Color@6x.png"
              />
              <div
                style={{
                  fontSize: 40,
                  fontWeight: 'bold',
                  color: '#000',
                  padding: 10,
                }}
              >
                730 S. Kingsley Dr.
              </div>
              <div style={{ fontSize: 20, padding: 10 }}>
                http://www.730kingsley.com
              </div>
              <div style={{ color: '#000' }}>
                730 S. Kingsley Dr.
                <br />
                Los Angeles, CA 90005
              </div>
            </a>
          </div>
        </Col>
        <Col
          xs={24}
          md={24}
          lg={7}
          style={{ textAlign: 'center', padding: 10 }}
        >
          <div
            style={{
              border: '1px solid #DDD',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              borderRadius: 10,
              padding: 10,
            }}
          >
            <a
              href="http://www.757kingsley.com"
              style={{ width: '100%', justifyContent: 'center' }}
            >
              <img
                style={{ width: '100%', maxWidth: 220, margin: 10 }}
                alt={'757 Kingsley'}
                src="./assets/757k_logo.png"
              />
              <div
                style={{
                  fontSize: 40,
                  fontWeight: 'bold',
                  color: '#000',
                  padding: 10,
                }}
              >
                757 S. Kingsley Dr.
              </div>
              <div style={{ fontSize: 20, padding: 10 }}>
                http://www.757kingsley.com
              </div>
              <div style={{ color: '#000' }}>
                757 S. Kingsley Dr.
                <br />
                Los Angeles, CA 90005
              </div>
            </a>
          </div>
        </Col>
        <Col xs={0} sm={0} lg={1}></Col>
      </Row>
      <Footer style={{ textAlign: 'center' }}>{AppData.footer}</Footer>
    </Layout>
  );
};

export default App;
